import { ref } from "vue";
import { User } from "../context/Users/domain/User";
import { Dependencies } from "../context/Shared/infrastructure/Dependencies";
import { getUserData } from "../context/Users/application/getUserData";

// global state, created in module scope
const globalUser = ref(null as User | null);

//TODO: revisar si esta realmente seteando el globalUser.
export function useUser() {
  if (!globalUser.value) {
    //console.log("no tengo el usuario, vuelvo a obtenerlo");
    getUserData(Dependencies.getInstance().getUserRepository()).then((user) => {
      globalUser.value = user;
    });
  }
  return globalUser;
}
