import { CompanyId } from "~/context/Company/domain/CompanyId";
import { SynchronizationRepository } from "../domain/SynchronizationRepository";
import { Synchronization } from "../domain/Synchronization";

export function getSynchronizeBankLastMonth(repository: SynchronizationRepository, companyId: CompanyId): Promise<Synchronization> {
  return new Promise<Synchronization>((resolve, reject) => {
    repository
      .synchronizeBankLastMonth(companyId)
      .then(resolve)
      .catch((_error) => {
        reject(new Error("Could not start sinchronization data"));
      });
  });
}
