<template>
  <v-dialog persistent v-model="dialog">
    <v-card class="mx-auto" max-width="500">
      <v-card-text>
        <div> {{ message }}</div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" block @click="update()">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>

const dialog = ref(false);
const message = ref("");

watchEffect(() => {
  dialog.value = store.state.dialog;
  message.value = store.state.message;
});

const update = () => {
  store.dispatch("closeDialog");
};



</script>