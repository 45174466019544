import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { PreUserRepository } from "../domain/PreUserRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { PreUserValidate } from "../domain/PreUserValidate";


export function createApiPreUserRepository(): PreUserRepository {
  return new ApiPreUserRepository();
}

export class ApiPreUserRepository
  extends APIRepository
  implements PreUserRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  resendCode(): Promise<void> {
    console.log("call resendCode");
    try {
      return this.doPostNew("/api/welcome/resendCode", null, {});
    } catch (error) {
      console.log("error resendCode:" + error);
      throw error;
    }
  }

  validateCode(preUserValidate: PreUserValidate): Promise<void> {
    console.log("call validateCode", JSON.stringify(preUserValidate));
    try {
      return this.doPostNew("/api/welcome/validateCode", null, {
        ...preUserValidate,
        code: preUserValidate.code,
      });
    } catch (error) {
      console.log("error validateCode:" + error);
      return Promise.reject();
    }
  }

  completeUser(firstName: string, lastName: string, gen: string): Promise<void> {
    console.log("call completeUser", JSON.stringify(firstName), JSON.stringify(lastName), JSON.stringify(gen));
    try {
      return this.doPostNew("/api/welcome/completeUser", null, {
        firstName: firstName,
        lastName: lastName,
        gen: gen,
      });
    } catch (error) {
      console.log("error completeUser:" + error);
      return Promise.reject();
    }
  }

  async validateUser(): Promise<PreUserValidate> {
    //console.log("call validateUser");
    try {
      const myPreUserValidate = await this.doGet<PreUserValidate>("/api/welcome/validateUser");
      //console.log("myPreUserValidate:", JSON.stringify(myPreUserValidate));
      return myPreUserValidate;

    } catch (error: any) {
      //console.log("***********error validateUser:" + JSON.stringify(error));
      if (error?.type === "PreUserIsValidatedError") {
        console.log("error validateUser (PreUserIsValidatedError):" + JSON.stringify(error));
      } else {
        console.log("error validateUser:" + JSON.stringify(error));
      }

      return Promise.reject(error.message);
    }
  }



}
