import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { ReconciliationReferenceRepository } from "../domain/ReconciliationReferenceRepository";
import { ReconciliationReference } from "../domain/ReconciliationReference";

export function createApiReconciliationReferenceRepository(): ReconciliationReferenceRepository {
  return new ApiReconciliationReferenceRepository();
}

export class ApiReconciliationReferenceRepository extends APIRepository implements ReconciliationReferenceRepository {
  constructor() {
    super(vectiAppApiUrl());
  }
  addReconciliationReference(_reconciliationReference: ReconciliationReference): Promise<void> {
    throw new Error("Method not implemented.");
  }
  getReconciliationReference(_reconciliationReferenceUid: string): Promise<ReconciliationReference | undefined> {
    throw new Error("Method not implemented.");
  }
  getReconciliationsReferenceByReconciliationUid(_reconciliationlUid: string): Promise<ReconciliationReference[] | undefined> {
    throw new Error("Method not implemented.");
  }
  getReconciliationsReferenceByReconciliationUidAndType(_reconciliationUid: string, _reconciliationReferenceTypeId: string): Promise<ReconciliationReference[] | undefined> {
    throw new Error("Method not implemented.");
  }
  getReconciliationsReferenceByReconciliationUidAndNDays(_reconciliationUid: string, _reconciliationNDays: number): Promise<ReconciliationReference[] | undefined> {
    throw new Error("Method not implemented.");
  }
  getReconciliationsReferenceByObjectUidAndType(objectUid: string, type: string): Promise<ReconciliationReference[]> {
    console.log("/api/references/type/" + type.toString() + "/objectId/" + objectUid.toString());
    return this.doGet<ReconciliationReference[]>("/api/references/type/" + type.toString() + "/objectId/" + objectUid.toString());
  }



}
