import { CompanyId } from "~/context/Company/domain/CompanyId";
import { SynchronizationRepository } from "../domain/SynchronizationRepository";
import { SynchronizationYearMonth } from "../domain/SynchronizationYearMonth";
import { VectiResult } from "~/context/Shared/domain/VectiResult";

export async function getSynchronizeSIIYearMonth(repository: SynchronizationRepository, companyId: CompanyId, monthsYear: string[]): Promise<VectiResult> {
  try {
    let yearMonths: SynchronizationYearMonth[] = [];
    for (let i = 0; i < monthsYear.length; i++) {
      const sync_year = monthsYear[i].substring(0, 4);
      const sync_month = monthsYear[i].substring(5, 7);
      const myMY = { sync_year, sync_month } as SynchronizationYearMonth;
      yearMonths.push(myMY);
    }
    console.log("getSynchronizeSIIYearMonth-->:" + JSON.stringify(yearMonths));

    await repository.getSynchronizeSIIYearMonth(companyId, yearMonths);
    return new VectiResult(true, "Sync SII ok");

  } catch (error) {
    return new VectiResult(false, "Sync SII error");

  }


}
