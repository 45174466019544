import { ReconciliationData } from "~/context/Reconciliation/domain/ReconciliationData";
import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { ReconciliationDataRepository } from "../domain/ReconciliationDataRepository";

export function createApiReconciliationDataRepository(): ReconciliationDataRepository {
  return new APIReconciliationDataRepository();
}

export class APIReconciliationDataRepository extends APIRepository implements ReconciliationDataRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  getReconciliationStatusByCompanyUid(companyUid: string): Promise<ReconciliationData> {
    console.log("getReconciliationStatusByCompanyUid companyUid: " + companyUid);
    return this.doGet<ReconciliationData>("/api/reconciliationsdata/company/" + companyUid.toString());
  }

  //TODO cambiar tipo de retorno
  getIncomesLastXDays(companyUid: string, days: number): Promise<ReconciliationData> {
    // console.log("getIncomesLast30Days companyUid: " + companyUid);
    return this.doGet<ReconciliationData>("/api/reconciliationsdata/company/" + companyUid.toString() + "/incomesdays/" + days.toString());
  }

  getIncomesMonth(companyUid: string, month: number): Promise<ReconciliationData> {
    // console.log("getIncomesMonth companyUid: " + companyUid + " month: " + month);
    return this.doGet<ReconciliationData>("/api/reconciliationsdata/company/" + companyUid.toString() + "/incomes/month/" + month.toString());
  }

  async getIncomesQuarter(companyUid: string, untilMonth: number): Promise<ReconciliationData[]> {
    // console.log("getIncomesQuarter companyUid: " + companyUid + " untilMonth: " + untilMonth);
    const ret = await this.doGet<ReconciliationData[]>("/api/reconciliationsdata/company/" + companyUid.toString() + "/incomesquarter/" + untilMonth.toString());
    //  console.log("ret:" + JSON.stringify(ret));
    return ret;
  }

  async getBilledQuarter(companyUid: string, untilMonth: number): Promise<ReconciliationData[]> {
    // console.log("getBilledQuarter companyUid: " + companyUid + " untilMonth: " + untilMonth);
    const ret = await this.doGet<ReconciliationData[]>("/api/reconciliationsdata/company/" + companyUid.toString() + "/billedquarter/" + untilMonth.toString());
    // console.log("ret:" + JSON.stringify(ret));
    return ret;
  }

  async getPendingPaidQuarter(companyUid: string, untilMonth: number): Promise<ReconciliationData[]> {
    // console.log("getPendingPaidQuarter companyUid: " + companyUid + " untilMonth: " + untilMonth);
    const ret = await this.doGet<ReconciliationData[]>("/api/reconciliationsdata/company/" + companyUid.toString() + "/pendingpaidquarter/" + untilMonth.toString());
    // console.log("ret:" + JSON.stringify(ret));
    return ret;
  }

  async getSpentQuarter(companyUid: string, untilMonth: number): Promise<ReconciliationData[]> {
    //console.log("getSpentLastQuarter companyUid: " + companyUid + " untilMonth: " + untilMonth);
    const ret = await this.doGet<ReconciliationData[]>("/api/reconciliationsdata/company/" + companyUid.toString() + "/spentquarter/" + untilMonth.toString());
    //console.log("ret:" + JSON.stringify(ret));
    return ret;
  }



}
