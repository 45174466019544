import { Company } from "../domain/Company";
import { CompanyRepository } from "../domain/CompanyRepository";
import { CompanyId } from "../domain/CompanyId";
import { APIRepository } from "../../Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "../../Shared/infrastructure/Config";
import { CompanyAditionalData } from "../domain/CompanyAditionalData";

export function createApiCompanyRepository(): CompanyRepository {
  return new ApiCompanyRepository();
}

export class ApiCompanyRepository
  extends APIRepository
  implements CompanyRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  getCompaniesByUserUid(userUid: string): Promise<Company[]> {
    return this.doGet<Company[]>("/api/companies", {
      userId: userUid.toString(),
    });
  }

  getCompanyByCompanyId(companyId: CompanyId): Promise<Company | undefined> {
    console.log("buscando found: " + JSON.stringify(companyId));
    const ret = this.doGet<Company>("/api/companies/company/" + companyId.value);
    console.log("Company found: " + JSON.stringify(ret));
    return ret;
  }

  saveAditionalData(data: CompanyAditionalData): Promise<void> {
    return this.doPostNew("/api/companies/aditionalData", null, { companyData: data });
  }
}
