import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { PreCompanyRepository } from "../domain/PreCompanyRepository";
import { PreCompanyValidate } from "../domain/PreCompanyValidate";
import { PreCompany } from "../domain/PreCompany";

export function createApiPreCompanyRepository(): PreCompanyRepository {
  return new ApiPreCompanyRepository();
}

export class ApiPreCompanyRepository
  extends APIRepository
  implements PreCompanyRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  validateCompany(preCompanyValidate: PreCompanyValidate): Promise<void> {
    console.log("call validateCompany", JSON.stringify(preCompanyValidate));
    try {
      return this.doPostNew("/api/welcome/validateCompany", null, {
        ...preCompanyValidate,
        legalId: preCompanyValidate.legalId,
        companyName: preCompanyValidate.companyName,
      });
    } catch (error: any) {
      if (error?.type === "PreCompanyExistsAsCompanyOnVectiError") {
        console.log("PreCompanyExistsAsCompanyOnVectiError");
        return Promise.reject();
      }
      console.log("error validateCompany:" + error);
      return Promise.reject();
    }
  }

  saveCompany(preCompanyValidate: PreCompanyValidate): Promise<void> {
    console.log("call saveCompany", JSON.stringify(preCompanyValidate));
    try {
      return this.doPostNew("/api/welcome/saveCompany", null, {
        ...preCompanyValidate,
        legalId: preCompanyValidate.legalId,
        companyName: preCompanyValidate.companyName,
        companyAddress: preCompanyValidate.companyAddress,
        companyCity: preCompanyValidate.companyCity,
        companyState: preCompanyValidate.companyState,
        companyZip: preCompanyValidate.companyZip,
        companyCountry: preCompanyValidate.companyCountry,
        companyEmail: preCompanyValidate.companyEmail,
        companyPhone: preCompanyValidate.companyPhone,
        companyWebsite: preCompanyValidate.companyWebsite,
      });
    } catch (error: any) {
      console.log("error saveCompany:" + error);
      return Promise.reject();
    }
  }

  validateCompanyGetCompany(preCompanyValidate: PreCompanyValidate): Promise<PreCompanyValidate> {
    console.log("call validateCompanyStep2", JSON.stringify(preCompanyValidate));

    try {

      return this.doGet<PreCompanyValidate>("/api/welcome/validateCompanyGetCompany/legalId/" + preCompanyValidate.legalId + "/companyName/" + preCompanyValidate.companyName);

    } catch (error) {
      console.log("error validateCompany:" + error);
      return Promise.reject();
    }
  }

  getByLegalId(legalId: string): Promise<PreCompany> {
    //console.log("call getByLegalId", JSON.stringify(legalId));

    try {

      return this.doGet<PreCompany>("/api/precompanies/legalId/" + legalId);

    } catch (error) {
      console.log("error validateCompany:" + error);
      return Promise.reject();
    }
  }

}
