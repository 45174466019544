import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { SpotExpenseRepository } from "../domain/SpotExpenseRepository";
import { SpotExpense } from "../domain/SpotExpense";

export function createApiSpotExpenseRepository(): SpotExpenseRepository {
  return new ApiSpotExpenseRepository();
}

export class ApiSpotExpenseRepository
  extends APIRepository
  implements SpotExpenseRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  addSpotExpense(spotExpense: SpotExpense): Promise<void> {
    console.log("call addSpotExpense", JSON.stringify(spotExpense));
    try {
      return this.doPostNew("/api/spotexpense", null, {
        ...spotExpense,
        uid: spotExpense.uid.value,
        company_uid: spotExpense.company_uid,
      });
    } catch (error) {
      console.log("error addSpotExpense:" + error);
      return Promise.reject();
    }
  }
}
