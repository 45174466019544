import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { SIITokenRepository } from "../domain/SIITokenRepository";
import { SIIToken } from "../domain/SIIToken";

export function createApiSIITokenRepository(): SIITokenRepository {
  return new ApiSIITokenRepository();
}

export class ApiSIITokenRepository
  extends APIRepository
  implements SIITokenRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  addSIIToken(sIIToken: SIIToken): Promise<void> {
    console.log("call SIIToken:", JSON.stringify(sIIToken));
    try {
      return this.doPostNew("/api/siitokens", null, {
        ...sIIToken,
        uid: sIIToken.uid.value,
        company_uid: sIIToken.company_uid,
        aggregator_uid: sIIToken.aggregator_uid,
        token: sIIToken.token
      });
    } catch (error) {
      console.log("error addSIIToken:" + error);
      return Promise.reject();
    }
  }

  addSIITokenWelcome(sIIToken: SIIToken): Promise<SIIToken> {
    console.log("call SIIToken:", JSON.stringify(sIIToken));
    try {
      const token = this.doPost<SIIToken, SIIToken>("/api/siitokens", null, {
        uid: sIIToken.uid,
        company_uid: sIIToken.company_uid,
        aggregator_uid: sIIToken.aggregator_uid,
        token: sIIToken.token
      });

      return token;
    } catch (error) {
      console.log("error addSIIToken:" + error);
      return Promise.reject();
    }
  }

}
