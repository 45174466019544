import { getFintoc } from "@fintoc/fintoc-js";
import { VectiResult } from "~/context/Shared/domain/VectiResult";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";


export function addBank(): Promise<VectiResult> {
  return new Promise(async (resolve, reject) => {

    const webhookUrl = vectiAppApiUrl() + "/api/fintocaccountlink";
    console.log("webhookUrl" + webhookUrl);
    // Inicia el proceso de agregar cuenta en FinToc
    const Fintoc = await getFintoc();
    const widget = Fintoc?.create({
      publicKey: "pk_live_uiTkuLSaoKzVHLcX6esUDR84J6Ad2LwD",
      holderType: "business",
      product: "Movements",
      country: "cl",
      webhookUrl,
      //        institutionId: "cl_banco_bice",
      onSuccess: () => {
        widget?.destroy();
        console.log("fintoc : sucess");
        resolve(new VectiResult(true, "success"));
        //      return Promise.resolve("success");
      },
      onExit: () => {
        widget?.destroy();
        console.log("fintoc : error");
        reject(new VectiResult(false, "cierre sin finalizar"));
        //      return new VectiResult(false, "cierre sin finalizar");

      },
      onEvent: (eventName: any, metadata: any) => {
        console.log("event ->:" + JSON.stringify(eventName));
        console.log("metadata:" + JSON.stringify(metadata));

      },
    });
    widget?.open();

  });

};