<template>
  <div persistent :model="showNotification" :close-delay="durationNotification" :class="classNotif">
    <div class="vecti-notification">
      {{ messageNotification }}
    </div>
  </div>
</template>

<script setup>
let shownotif = true;
let showNotification = ref(false);
let durationNotification = ref(2000);
let messageNotification = ref("");
let classNotif = "notificationHidden";

watchEffect(() => {

  showNotification.value = store.state.notification.show;
  if (store.state.notification.show) {
    classNotif = "notification"
  } else {
    classNotif = "notificationHidden"
  }
  durationNotification.value = store.state.notification.duration;
  messageNotification.value = store.state.notification.message;

});



</script>
<style>
.notification {
  position: fixed !important;
  top: 100px;
  right: 0px;
  z-index: 10;
  visibility: visible;
}

.notificationHidden {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 0;
  visibility: hidden;
  padding: 0px;
  width: 0%;
}
</style>