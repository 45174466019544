import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { EmployeeRepository } from "../domain/EmployeeRepository";
import { EmployeeFileDT } from "../domain/EmployeeFileDT";
import { EmployeeFileDTResponse } from "../domain/EmployeeFileDTResponse";
import { Employee } from "../domain/Employee";
import { EmployeeDataFull } from "~/context/EmployeesData/domain/EmployeeDataFull";

export function createApiEmployeeRepository(): EmployeeRepository {
  return new APIEmployeeRepository();
}

export class APIEmployeeRepository
  extends APIRepository
  implements EmployeeRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  async addEmployeesFromDT(employeesFileDT: EmployeeFileDT): Promise<EmployeeFileDTResponse> {
    console.log("addEmployeesFromDT companyId: " + employeesFileDT.company_uid.toString());
    try {
      const resp = await this.doPost<EmployeeFileDT, EmployeeFileDTResponse>("/api/employees/upload/DT", null, employeesFileDT);
      return resp;
    } catch (error) {
      console.log("error addEmployeesFromDT:" + error);
      return { ok: false, message: "Error: " + error, company_uid: employeesFileDT.company_uid, records: 0, created: new Date() } as EmployeeFileDTResponse;
    }
  }

  async getEmployee360ByCompanyAndId(companyId: string, employeeId: string): Promise<EmployeeDataFull> {
    console.log("getEmployee360ByCompanyAndId companyId: " + companyId);
    return this.doGet<EmployeeDataFull>("/api/employees/employee/" + employeeId.toString() + "/company/" + companyId.toString());
  }

  async getEmployeeByCompanyAndLegalId(companyId: string, legalId: string): Promise<Employee> {
    console.log("getEmployeeByCompanyAndLegalId companyId: " + companyId + " legalId: " + legalId);
    try {
      const resp = await this.doGet<Employee>("/api/employees/company/" + companyId + "/legalId/" + legalId);
      console.log("getEmployeeByCompanyAndLegalId resp: " + JSON.stringify(resp));
      return resp;
    } catch (error) {
      console.log("error getEmployeeByCompanyAndLegalId:" + error);
      return {
        uid: "",
        company_uid: "",
        employee_legal_id: "",
        employee_start_date: "",
        employee_end_date: "",
        employee_legal_name: "",
        employee_first_name: "",
        employee_last_name: "",
        employee_email: "",
        employee_phone: "",
        employee_address: "",
        employee_city: "",
        employee_gender: "",
      } as unknown as Employee;
    }
  }

}
