import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { DocumentRepository } from "../domain/DocumentRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { Criteria } from "~/context/Shared/domain/criteria/Criteria";
import { Page } from "~/context/Shared/domain/page/Page";
import { PageFull } from "~/context/Shared/domain/page/PageFull";
import { Document } from "../domain/Document";
import { SearchData } from "~/context/Shared/domain/criteria/SearchData";

export function createApiDocumentRepository(): DocumentRepository {
  return new ApiDocumentRepository();
}


export class ApiDocumentRepository extends APIRepository implements DocumentRepository {
  constructor() {
    super(vectiAppApiUrl());
  }


  async getDocumentsByLegalId(_legalId: string): Promise<Document[]> {
    throw new Error("Method not implemented.");
    //const a = await this.doGet<Document[]>("/api/documents/company/legalId" + legalId.toString());
    //return a;
  }

  async getDocumentsByCompanyUid(companyUid: string): Promise<Document[]> {
    //console.log("call getDocumentsByCompanyUid: " + companyUid.toString());
    const a = await this.doGet<Document[]>("/api/documents/company/" + companyUid.toString());
    // console.log("return getDocumentsByCompanyUid: " + JSON.stringify(a));
    return a;
  }

  async getDocumentBySpotExpenseId(spotExpenseUid: string, companyUid: string): Promise<Document> {
    const a = await this.doGet<Document>("/api/documents/company/" + companyUid.toString() + "/expense/spot/" + spotExpenseUid);
    console.log("return getDocumentBySpotExpenseId: " + JSON.stringify(a));
    return a;
  }


  async getIncomeDocumentsByCompanyAndClient(companyUid: string, clientUid: string): Promise<Document[]> {
    const docs = await this.doGet<Document[]>("/api/documents/company/" + companyUid.toString() + "/client/" + clientUid.toString());
    return docs;
  }

  async getOutcomeDocumentsByCompanyAndProvider(companyUid: string, providerUid: string): Promise<Document[]> {
    console.log("getOutcomeDocumentsByCompanyAndProvider: " + companyUid + " providerUid: " + providerUid);
    const docs = await this.doGet<Document[]>("/api/documents/company/" + companyUid.toString() + "/provider/" + providerUid.toString());
    return docs;
  }

  async getOutcomeDocumentsByCompanyAndEmployee(companyUid: string, employeeUid: string): Promise<Document[]> {
    console.log("getOutcomeDocumentsByCompanyAndEmployee: " + companyUid + " employeeUid: " + employeeUid);
    const docs = await this.doGet<Document[]>("/api/documents/company/" + companyUid.toString() + "/employee/" + employeeUid.toString());
    return docs;
  }



  async getDocumentsByCompanyUidAndCriteriaInfra(companyUid: string, criteria: Criteria): Promise<void> {
    //console.log("call getDocumentsByCompanyUid: " + companyUid.toString());

    const a = await this.doPostNew("/api/documents/company/criteria", null, {
      companyUid: companyUid,
      criteria: criteria
    });

    return a;
  }

  async getIncomeDocumentsByCompanyUid(companyUid: string): Promise<Document[]> {
    const a = await this.doGet<Document[]>("/api/documents/income/company/" + companyUid.toString());
    return a;
  }

  async getOutcomeDocumentsByCompanyUid(companyUid: string): Promise<Document[]> {
    const a = await this.doGet<Document[]>("/api/documents/outcome/company/" + companyUid.toString());
    console.log("return getOutcomeDocumentsByCompanyUid: " + JSON.stringify(a));
    return a;
  }


  async getDocumentByDocumentUidAndType(documentUid: string, type: string): Promise<Document> {
    //console.log("call getDocumentsBgetDocumentByDocumentUidAndTypeyDocumentUid: " + documentUid.toString());
    const response = await this.doGet<Document>("/api/documents/" + documentUid.toString() + "/type/" + type.toString());
    //console.log("return getDocumentByDocumentUidAndType: " + JSON.stringify(response));
    return response;
  }



  async getDocumentsByCompanyUidDistance(companyId: string, movementId: string): Promise<Document[]> {
    const a = await this.doGet<Document[]>("/api/documents/company/" + companyId.toString() + "/movement/" + movementId.toString());
    return a;
  }

  getDocumentsFullRepositoryBySearch(search: SearchData): Promise<Document[]> {
    return this.doPost<SearchData, Document[]>("/api/documents/full/company/search", null, search);
  }



  async getDocumentsByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<Page<Document>> {
    try {
      const documents = await this.getDocumentsByCompanyUid(companyUid);
      const pageSize = criteria.pageSize;
      const start = (criteria.pageNumber - 1) * pageSize;
      const end = criteria.pageNumber * pageSize;
      const paginatedDocuments = this.filterByCriteria(criteria, documents);
      const returnpaginatedDocuments = paginatedDocuments.slice(start, end)
      console.log("getDocumentsByCompanyAndCriteria paginatedDocuments:" + JSON.stringify(returnpaginatedDocuments?.length));
      return Promise.resolve(
        new Page<Document>(
          returnpaginatedDocuments?.length,
          returnpaginatedDocuments
        )
      );

    } catch (error) {
      console.log("Error getFullIncomeDocumentsByCompanyClientAndCriteria:" + error)
      let documents = Array<Document>();
      return new Page<Document>(
        0,
        documents
      );
    }


  }
  /*
    async getFullDocumentsByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<PageFull<Document>> {
      const documents = await this.getDocumentsByCompanyUid(companyUid);
      const pageSize = criteria.pageSize;
      const start = (criteria.pageNumber - 1) * pageSize;
      const end = criteria.pageNumber * pageSize;
      const paginatedDocuments = this.filterByCriteria(criteria, documents);
      return Promise.resolve(
        new PageFull<Document>(
          documents.length,
          paginatedDocuments.length,
          paginatedDocuments.slice(start, end)
        )
      );
    }
    */

  async getFullDocumentsByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<PageFull<Document>> {
    let documents = Array<Document>();
    console.log("this.documentCache.length: " + this.documentCache.length);
    if (this.documentCache.length == 0) {
      documents = await this.getDocumentsByCompanyUid(companyUid);
      this.documentCache = documents;
    } else {
      documents = this.documentCache;
    }

    const pageSize = criteria.pageSize;
    const start = (criteria.pageNumber - 1) * pageSize;
    const end = criteria.pageNumber * pageSize;

    const paginatedDocuments = this.filterByCriteria(criteria, documents);
    return Promise.resolve(
      new PageFull<Document>(
        documents.length,
        paginatedDocuments.length,
        paginatedDocuments.slice(start, end)
      )
    );
  }

  async getFullIncomeDocumentsByCompanyClientAndCriteria(companyUid: string, clientUid: string, criteria: Criteria): Promise<PageFull<Document>> {
    let documents = Array<Document>();

    try {
      documents = await this.getIncomeDocumentsByCompanyAndClient(companyUid, clientUid);

      const pageSize = criteria.pageSize;
      const start = (criteria.pageNumber - 1) * pageSize;
      const end = criteria.pageNumber * pageSize;

      const paginatedDocuments = this.filterByCriteria(criteria, documents);
      return Promise.resolve(
        new PageFull<Document>(
          documents.length,
          paginatedDocuments.length,
          paginatedDocuments.slice(start, end)
        )
      );
    } catch (error) {
      console.log("Error getFullIncomeDocumentsByCompanyClientAndCriteria:" + error)
      return new PageFull<Document>(
        0,
        0,
        documents
      );
    }

  }



  async getFullOutcomeDocumentsByCompanyProviderAndCriteria(companyUid: string, providerUid: string, criteria: Criteria): Promise<PageFull<Document>> {
    let documents = Array<Document>();

    try {
      documents = await this.getOutcomeDocumentsByCompanyAndProvider(companyUid, providerUid);

      const pageSize = criteria.pageSize;
      const start = (criteria.pageNumber - 1) * pageSize;
      const end = criteria.pageNumber * pageSize;

      const paginatedDocuments = this.filterByCriteria(criteria, documents);
      return Promise.resolve(
        new PageFull<Document>(
          documents.length,
          paginatedDocuments.length,
          paginatedDocuments.slice(start, end)
        )
      );
    } catch (error) {
      console.log("Error getFullOutcomeDocumentsByCompanyProviderAndCriteria:" + error)
      return new PageFull<Document>(
        0,
        0,
        documents
      );
    }

  }


  async getFullOutcomeDocumentsByCompanyEmployeeAndCriteria(companyUid: string, employeeUid: string, criteria: Criteria): Promise<PageFull<Document>> {
    let documents = Array<Document>();

    try {
      documents = await this.getOutcomeDocumentsByCompanyAndEmployee(companyUid, employeeUid);

      const pageSize = criteria.pageSize;
      const start = (criteria.pageNumber - 1) * pageSize;
      const end = criteria.pageNumber * pageSize;

      const paginatedDocuments = this.filterByCriteria(criteria, documents);
      return Promise.resolve(
        new PageFull<Document>(
          documents.length,
          paginatedDocuments.length,
          paginatedDocuments.slice(start, end)
        )
      );
    } catch (error) {
      console.log("Error getFullOutcomeDocumentsByCompanyEmployeeAndCriteria:" + error)
      return new PageFull<Document>(
        0,
        0,
        documents
      );
    }

  }

  async getSplitFullOutcomeDocumentsByCompanyEmployeeAndCriteria(companyUid: string, employeeUid: string, criteria: Criteria): Promise<[PageFull<Document>, PageFull<Document>]> {
    let documents = Array<Document>();
    console.log("getSplitFullOutcomeDocumentsByCompanyEmployeeAndCriteria:");
    try {
      documents = await this.getOutcomeDocumentsByCompanyAndEmployee(companyUid, employeeUid);

      const pageSize = criteria.pageSize;
      const start = (criteria.pageNumber - 1) * pageSize;
      const end = criteria.pageNumber * pageSize;

      const paginatedDocuments = this.filterByCriteria(criteria, documents);
      //crea dos arreglos, uno con los documentos con balance_amount>0 y otro con el resto siempre y cuando el balance no sea nulo o indefinido.

      console.log(" getSplitFullOutcomeDocumentsByCompanyEmployeeAndCriteria (paginatedDocuments):" + JSON.stringify(paginatedDocuments));

      const paginatedDocumentsNotReconciliated = paginatedDocuments.filter((document) => {
        console.log("document.document_balance: " + document.document_balance);
        console.log("condicion:" + (document.document_balance != null && document.document_balance > 0));
        if (document.document_balance != null && document.document_balance > 0) {
          return true;
        } else {
          return false;
        }
      });
      console.log(" getSplitFullOutcomeDocumentsByCompanyEmployeeAndCriteria (paginatedDocumentsNotReconciliated):" + JSON.stringify(paginatedDocumentsNotReconciliated));
      const paginatedDocumentsReconciliated = paginatedDocuments.filter((document) => document.document_balance === 0);

      return Promise.all([
        new PageFull<Document>(
          paginatedDocuments.length,
          paginatedDocumentsNotReconciliated.length,
          paginatedDocumentsNotReconciliated.slice(start, end)
        ),
        new PageFull<Document>(
          paginatedDocuments.length,
          paginatedDocumentsReconciliated.length,
          paginatedDocumentsReconciliated.slice(start, end)
        )]
      );
    } catch (error) {
      console.log("Error getFullOutcomeDocumentsByCompanyEmployeeAndCriteria:" + error)
      return [new PageFull<Document>(
        0,
        0,
        documents
      ),
      new PageFull<Document>(
        0,
        0,
        documents
      )
      ];
    }

  }








  /*
    async getFullIncomeDocumentsByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<PageFull<Document>> {
      const documents = await this.getIncomeDocumentsByCompanyUid(companyUid);
      const pageSize = criteria.pageSize;
      const start = (criteria.pageNumber - 1) * pageSize;
      const end = criteria.pageNumber * pageSize;
      const paginatedDocuments = this.filterByCriteria(criteria, documents);
      return Promise.resolve(
        new PageFull<Document>(
          documents.length,
          paginatedDocuments.length,
          paginatedDocuments.slice(start, end)
        )
      );
    }
  */
  documentCache: Document[] = [];
  documentOutcomeCache: Document[] = [];
  documentIncomeCache: Document[] = [];
  cacheSize = 1000;

  async getFullIncomeDocumentsByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<PageFull<Document>> {
    let documents = Array<Document>();
    // let documentsTMP = await this.getDocumentsByCompanyUidAndCriteriaInfra(companyUid, criteria);
    console.log("criteria page number: " + criteria?.pageNumber);
    //  console.log("documentsTMP X: " + JSON.stringify(documentsTMP));
    console.log("getFullIncomeDocumentsByCompanyAndCriteria: " + JSON.stringify(this.documentIncomeCache));
    console.log("criteria?.pageNumber === 1: " + (criteria?.pageNumber === 1));
    console.log("this.documentIncomeCache.length: " + (this.documentIncomeCache.length == 0));
    console.log("this.documentIncomeCache.length === undefined: " + (this.documentIncomeCache.length === undefined));



    if (criteria?.pageNumber === 1 || (this.documentIncomeCache.length == 0 || this.documentIncomeCache.length === undefined)) {
      console.log("no data getFullIncomeDocumentsByCompanyAndCriteria : ");
      documents = await this.getIncomeDocumentsByCompanyUid(companyUid);
      console.log("llame api:" + documents.length);
      this.documentIncomeCache = documents;
    } else {
      console.log("sin llamar  api:" + documents.length);
      documents = this.documentIncomeCache;
    }
    //let pageRetrieved = this.cacheSize / criteria.pageSize;

    const pageSize = criteria.pageSize;
    const start = (criteria.pageNumber - 1) * pageSize;
    const end = criteria.pageNumber * pageSize;
    console.log("start Y:" + start);
    console.log("end:" + end);
    const paginatedDocuments = this.filterByCriteria(criteria, documents);

    const returnpaginatedDocuments = paginatedDocuments.slice(start, end)


    console.log("paginatedDocuments:" + JSON.stringify(paginatedDocuments.length));

    //    const documents = await this.getIncomeDocumentsByCompanyUid(companyUid);

    return Promise.resolve(
      new PageFull<Document>(
        documents.length,
        returnpaginatedDocuments.length,
        returnpaginatedDocuments
      )
    );
  }


  async getFullOutcomeDocumentsByCompanyAndCriteria(companyUid: string, criteria: Criteria): Promise<PageFull<Document>> {
    let documents = Array<Document>();
    console.log("getFullOutcomeDocumentsByCompanyAndCriteria: " + this.documentOutcomeCache);
    if (criteria?.pageNumber === 1 || (this.documentOutcomeCache.length == 0 || this.documentOutcomeCache.length === undefined)) {
      documents = await this.getOutcomeDocumentsByCompanyUid(companyUid);
      console.log("llame api:" + documents.length);
      this.documentOutcomeCache = documents;
    } else {
      console.log("sin llamar  api:" + documents.length);
      documents = this.documentOutcomeCache;
    }

    const pageSize = criteria.pageSize;
    const start = (criteria.pageNumber - 1) * pageSize;
    const end = criteria.pageNumber * pageSize;
    const paginatedDocuments = this.filterByCriteria(criteria, documents);
    console.log("paginatedDocuments:" + JSON.stringify(paginatedDocuments));

    return Promise.resolve(
      new PageFull<Document>(
        documents.length,
        paginatedDocuments.length,
        paginatedDocuments.slice(start, end)
      )
    );
  }





  async getDocumentsByCompanyDistanceAndCriteria(companyId: string, movementId: string, criteria: Criteria): Promise<Page<Document>> {
    console.log("getDocumentsByCompanyDistanceAndCriteria: companyId: " + companyId + " movementId: " + movementId + " criteria: " + JSON.stringify(criteria));
    const documents = await this.getDocumentsByCompanyUidDistance(companyId, movementId);
    const pageSize = criteria.pageSize;
    const start = (criteria.pageNumber - 1) * pageSize;
    let end = criteria.pageNumber * pageSize;
    console.log("getDocumentsByCompanyDistanceAndCriteria: start: ", start, " end: ", end, " pageSize: ", pageSize, " criteria: ", JSON.stringify(criteria));
    console.log("getDocumentsByCompanyDistanceAndCriteria: + invoices length: ", documents.length);
    console.log("getDocumentsByCompanyDistanceAndCriteria: + criteria: ", (pageSize * (criteria.pageNumber + 1)));

    const paginatedDocuments = this.filterByCriteria(criteria, documents);
    if (documents.length < (pageSize * (criteria.pageNumber + 1))) {
      end = documents.length;
    }
    console.log("getDocumentsByCompanyDistanceAndCriteria: paginatedInvoices length: ", paginatedDocuments.length);
    const retorno = paginatedDocuments.slice(start, end);
    console.log("getDocumentsByCompanyDistanceAndCriteria: retorno length: ", retorno.length);

    return Promise.resolve(
      new Page<Document>(
        paginatedDocuments.length,
        retorno
      )
    );

  }



  async getDocumentsFullRepositoryByCompanyAndSearch(companyUid: string, search: string): Promise<Page<Document>> {

    const searchData = new SearchData(companyUid, search);
    console.log("getDocumentsFullRepositoryByCompanyAndSearch: companyId" + companyUid + "->" + JSON.stringify(searchData));
    const documents = await this.getDocumentsFullRepositoryBySearch(searchData);
    console.log("getDocumentsFullRepositoryByCompanyAndSearch: documents:" + JSON.stringify(documents));
    return Promise.resolve(
      new Page<Document>(
        documents.length,
        documents
      )
    );
  }







  //TODO: este filtro pareciera que deberia estar en APIRepository
  filterByCriteria(
    criteria: Criteria,
    _documents: Document[]
  ): Document[] {
    //TODO: implementar filtros
    let documents = Array<Document>();
    if (criteria.filters.length > 0) {
      for (const filter of criteria.filters) {
        switch (filter.field) {
          case "company_uid": {
            if (_documents.length > 0) {
              documents = _documents.filter((document) =>
                document.company_uid.toString().toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "document_number": {
            if (_documents.length > 0) {
              documents = _documents.filter((document) =>
                document.document_number?.toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "document_type": {
            if (_documents.length > 0) {
              documents = _documents.filter((document) =>
                document.document_type
                  .toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "document_sender_legal_id": {
            if (_documents.length > 0) {
              documents = _documents.filter((document) =>
                document.document_sender_legal_id?.toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "document_state": {
            if (_documents.length > 0) {
              documents = _documents.filter((document) =>
                document.document_state?.toLowerCase()
                  .includes(filter.value.toLowerCase())
              );
            }
            break;
          }
          case "document_balance": {

            if (_documents.length > 0) {
              documents = _documents.filter((document) => {
                if (filter.operator === ">") {
                  console.log("filtrando por " + filter.operator);
                  return document.document_balance &&
                    (document.document_balance > parseInt(filter.value))
                }
                if (filter.operator === "<") {
                  return document.document_balance &&
                    (document.document_balance < parseInt(filter.value))
                }
                if (filter.operator === ">=") {
                  return document.document_balance &&
                    (document.document_balance >= parseInt(filter.value))
                }
                if (filter.operator === "<=") {
                  return document.document_balance &&
                    (document.document_balance <= parseInt(filter.value))
                }
                if (filter.operator === "=") {
                  console.log("filtrando por " + filter.operator + " value: " + filter.value);
                  console.log("document.document_balance  " + document.document_balance);
                  if ((document.document_balance === parseInt(filter.value))) {
                    console.log("document:  " + JSON.stringify(document));
                  }

                  return (document.document_balance === parseInt(filter.value))
                }
                return false;
              }

              );
              console.log("documents.length pre: " + documents.length);
            }

            break;
          }

        }
      }
    }
    console.log("documents.length: " + documents.length);
    return documents;
  }



}





