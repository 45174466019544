import { SIITaxF29 } from "~/context/SIITaxF29s/domain/SIITaxF29";
import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { SIITaxF29Repository } from "../domain/SIITaxF29Repository.ts";

export function createApiSIITaxF29Repository(): SIITaxF29Repository {
  return new ApiSIITaxF29Repository();
}

export class ApiSIITaxF29Repository
  extends APIRepository
  implements SIITaxF29Repository
{
  constructor() {
    super(vectiAppApiUrl());
  }

  addSIITaxF29(siitaxf29: SIITaxF29): Promise<void> {
    console.log("addSIITaxF22", JSON.stringify(siitaxf29));

    return this.doPostNew("/api/siitaxf29s", null, {
      ...siitaxf29,
      uid: siitaxf29.uid.value,
      company_uid: siitaxf29.company_uid.value,
    });
  }
}
