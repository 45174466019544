import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { ClientDataRepository } from "../domain/ClientDataRepository";
import { ClientDataDebtor } from "../domain/ClientDataDebtor";
import { ClientDataResume } from "../domain/ClientDataResume";

export function createApiClientDataRepository(): ClientDataRepository {
  return new APIClientDataRepository();
}

export class APIClientDataRepository
  extends APIRepository
  implements ClientDataRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  getTopClientDebtorsByCompanyUid(companyUid: string, top: number): Promise<ClientDataDebtor[]> {
    console.log("getTopClientDebtorsByCompanyUid companyUid: " + companyUid);
    return this.doGet<ClientDataDebtor[]>("/api/clientsdata/debtors/company/" + companyUid.toString() + "/top/" + top.toString());
  }

  getClientsWithDebtByCompany(companyId: string): Promise<ClientDataDebtor[]> {
    console.log("getClientsWithDebtByCompany companyId: " + companyId);
    return this.doGet<ClientDataDebtor[]>("/api/clientsdata/debtors/company/" + companyId.toString());
  }

  getClientsWithResumeByCompany(companyId: string): Promise<ClientDataResume[]> {
    console.log("getClientsWithDebtByCompany companyId: " + companyId);
    return this.doGet<ClientDataResume[]>("/api/clientsdata/debtors/resume/company/" + companyId.toString());
  }


}
