import { UserRepository } from "../../Users/domain/UserRepository";
import { createApiUserRepository as createUserRepository } from "../../Users/infrastructure/ApiUserRepository";
// import { createFixedUserRepository as createUserRepository } from "~/src/Users/infrastructure/FixedUserRepository";
import { AccountRepository } from "../../Accounts/domain/AccountRepository";
// import { createFixedAccountRepository } from "~/src/Accounts/infrastructure/FixedAccountRepository";
import { MovementRepository } from "../../Movements/domain/MovementRepository";
import { createApiMovementRepository as createMovementRepository } from "../../Movements/infrastructure/ApiMovementRepository";
import { createApiReconciliationRepository as createReconciliationRepository } from "../../Reconciliations/infrastructure/ApiReconciliationRepository";

// import { createFixedMovementRepository } from "~/src/Movements/infrastructure/FixedMovementRepository";
//import { createMemoryMovementRepository } from "../../Movements/infrastructure/MemoryMovementRepository";

import { InvoiceRepository } from "../../Invoices/domain/InvoiceRepository";
// import { createFixedInvoiceRepository } from "../../Invoices/infrastructure/FixedInvoiceRepository";
//import { createMemoryInvoiceRepository } from "../../Invoices/infrastructure/MemoryInvoiceRepository";
import { createApiInvoiceRepository as createInvoiceRepository } from "../../Invoices/infrastructure/ApiInvoiceRepository";
import { createApiDocumentRepository as createDocumentRepository } from "../../Documents/infrastructure/ApiDocumentRepository";
//import { createMemoryAccountRepository } from "../../Accounts/infrastructure/MemoryAccountRepository";
import { createApiAccountRepository as createAccountRepository } from "../../Accounts/infrastructure/ApiAccountRepository";
//import { createFixedCompanyRepository as createCompanyRepository } from "../../Company/infrastructure/FixedCompanyRepository";
import { createApiCompanyRepository as createCompanyRepository } from "../../Company/infrastructure/ApiCompanyRepository";
import { CompanyRepository } from "../../Company/domain/CompanyRepository";
import { createFixedReconciliationMovementRepository } from "../../ReconciliationMovement/infrastructure/FixedReconciliationMovementRepository";
import { ReconciliationMovementRepository } from "../../ReconciliationMovement/domain/ReconciliationMovementRepository";
//import { createMemoryReconciliationRepository } from "../../Reconciliation/infrastructure/MemoryReconciliationRepository";
import { ReconciliationRepository } from "../../Reconciliations/domain/ReconciliationRepository";
//import { ReconciliationReference } from "~/context/Reconciliation/domain/ReconciliationReference";
import { ReconciliationReferenceRepository } from "~/context/ReconciliationReferences/domain/ReconciliationReferenceRepository";
import { createApiReconciliationDataRepository as createReconciliationDataRepository } from "~/context/ReconciliationsData/infrastructure/ApiReconciliationDataRepository";

import { createApiReconciliationReferenceRepository as createReconciliationReferenceRepository } from "../../ReconciliationReferences/infrastructure/ApiReconciliationReferenceRepository";
import { DocumentRepository } from "~/context/Documents/domain/DocumentRepository";
import { SynchronizationRepository } from "~/context/Synchronizations/domain/SynchronizationRepository";
import { createApiSynchronizationRepository as createSynchronizationRepository } from "~/context/Synchronizations/infrastructure/ApiSynchronizationRepository";
import { createApiAutoreconciliationRepository as createAutoreconciliationRepository } from "~/context/Autoreconciliations/infraestructure/ApiAutoreconciliationRepository";
import { AutoreconciliationRepository } from "~/context/Autoreconciliations/domain/AutoreconciliationRepository";
import { createApiSIITaxF22Repository as createSIITaxF22Repository } from "~/context/SIITaxF22s/infrastruture/ApiSIITaxF22Repository.ts";
import { createApiSIITaxF29Repository as createSIITaxF29Repository } from "~/context/SIITaxF29s/infrastruture/ApiSIITaxF29Repository";
import { createApiExpenseWageRepository as createExpenseWageRepository } from "~/context/ExpenseWages/infrastructure/ApiExpenseWageRepository";
import { createApiSIITokenRepository as createSIITokenRepository } from "~/context/SIITokens/infrastructure/ApiSIITokenRepository";
import { createApiClientDataRepository as createClientDataRepository } from "~/context/ClientsData/infrastructure/ApiClientDataRepository";
import { createApiProviderDataRepository as createProviderDataRepository } from "~/context/ProvidersData/infrastructure/ApiProviderDataRepository";
import { createApiClientRepository as createClientRepository } from "~/context/Clients/infrastructure/ApiClientRepository";
import { createApiProviderRepository as createProviderRepository } from "~/context/Providers/infrastructure/ApiProviderRepository";
import { createApiPreUserRepository as createPreUserRepository } from "~/context/PreUsers/infrastructure/ApiPreUserRepository";
import { createApiPreCompanyRepository as createPreCompanyRepository } from "~/context/PreCompanies/infrastructure/ApiPreCompanyRepository";
import { createApiCollaboratorRepository as createCollaboratorRepository } from "~/context/Collaborators/infrastructure/ApiCollaboratorRepository";
import { createApiCollaboratorDataRepository as createCollaboratorDataRepository } from "~/context/CollaboratorsData/infrastructure/ApiCollaboratorDataRepository";
import { createApiEmployeeRepository as createEmployeeRepository } from "~/context/Employees/infrastructure/ApiEmployeeRepository";
import { createApiSpotExpenseRepository as createSpotExpenseRepository } from "~/context/SpotExpenses/infrastructure/ApiSpotExpenseRepository";
import { createApiEmployeeSalaryRepository as createEmployeeSalaryRepository } from "~/context/EmployeeSalaries/infrastructure/ApiEmployeeSalaryRepository"
import { createApiEmployeeDataRepository as createEmployeeDataRepository } from "~/context/EmployeesData/infrastructure/ApiEmployeeDataRepository"

export class Dependencies {
  // eslint-disable-next-line no-use-before-define
  private static instance: Dependencies;

  // permitimos un constructor privado que no permita crear instancias de esta clase
  // eslint-disable-next-line no-useless-constructor
  private constructor() { }

  static getInstance(): Dependencies {
    try {
      if (!Dependencies.instance) {
        Dependencies.instance = new Dependencies();
      }
      return Dependencies.instance;
    } catch (error) {
      console.log('Instanciando Dependencies', error);
      throw error;
    }
  }

  private accountRepository = createAccountRepository();
  private movementRepository = createMovementRepository();
  private invoiceRepository = createInvoiceRepository();
  private documentRepository = createDocumentRepository();
  private userRepository = createUserRepository();
  private companyRepository = createCompanyRepository();
  private reconciliationMovementRepository =
    createFixedReconciliationMovementRepository();

  private reconciliationRepository = createReconciliationRepository();

  private reconciliationReferenceRepository =
    createReconciliationReferenceRepository();
  private synchronizationRepository = createSynchronizationRepository();
  private autoreconciliationRepository = createAutoreconciliationRepository();
  private reconciliationDataRepository = createReconciliationDataRepository();
  private siiTaxF22Repository = createSIITaxF22Repository();
  private siiTaxF29Repository = createSIITaxF29Repository();
  private expenseWageRepository = createExpenseWageRepository();
  private siiTokenRepository = createSIITokenRepository();
  private clientDataRepository = createClientDataRepository();
  private providerDataRepository = createProviderDataRepository();
  private clientRepository = createClientRepository();
  private providerRepository = createProviderRepository();
  private preUserRepository = createPreUserRepository();
  private preCompanyRepository = createPreCompanyRepository();
  private collaboratorRepository = createCollaboratorRepository();
  private collaboratorDataRepository = createCollaboratorDataRepository();
  private employeeRepository = createEmployeeRepository();
  private spotExpenseRepository = createSpotExpenseRepository();
  private employeeSalaryRepository = createEmployeeSalaryRepository();
  private employeeDataRepository = createEmployeeDataRepository();

  setUserRepository(userRepository: UserRepository) {
    this.userRepository = userRepository;
  }

  getUserRepository(): UserRepository {
    return this.userRepository;
  }

  getAccountRepository(): AccountRepository {
    return this.accountRepository;
  }

  setAccountRepository(accountRepository: AccountRepository) {
    this.accountRepository = accountRepository;
  }

  getMovementRepository(): MovementRepository {
    return this.movementRepository;
  }

  setMovementRepository(movementRepository: MovementRepository) {
    this.movementRepository = movementRepository;
  }

  getInvoiceRepository(): InvoiceRepository {
    return this.invoiceRepository;
  }

  setInvoiceRepository(invoiceRepository: InvoiceRepository) {
    this.invoiceRepository = invoiceRepository;
  }

  getDocumentRepository(): DocumentRepository {
    return this.documentRepository;
  }

  setDocumentRepository(documentRepository: DocumentRepository) {
    this.documentRepository = documentRepository;
  }

  setCompanyRepository(companyRepository: CompanyRepository) {
    this.companyRepository = companyRepository;
  }

  getCompanyRepository(): CompanyRepository {
    return this.companyRepository;
  }

  setReconciliationMovementRepository(
    reconciliationMovementRepository: ReconciliationMovementRepository
  ) {
    this.reconciliationMovementRepository = reconciliationMovementRepository;
  }


  getReconciliationMovementRepository(): ReconciliationMovementRepository {
    return this.reconciliationMovementRepository;
  }

  setReconciliationRepository(
    reconciliationRepository: ReconciliationRepository
  ) {
    this.reconciliationRepository = reconciliationRepository;
  }

  getReconciliationRepository(): ReconciliationRepository {
    return this.reconciliationRepository;
  }

  setReconciliationReferenceRepository(
    reconciliationReferenceRepository: ReconciliationReferenceRepository
  ) {
    this.reconciliationReferenceRepository = reconciliationReferenceRepository;
  }

  getReconciliationReferenceRepository(): ReconciliationReferenceRepository {
    return this.reconciliationReferenceRepository;
  }

  getSynchronizationRepository(): SynchronizationRepository {
    return this.synchronizationRepository;
  }

  getAutoreconciliationRepository(): AutoreconciliationRepository {
    return this.autoreconciliationRepository;
  }

  getReconciliationDataRepository() {
    return this.reconciliationDataRepository;
  }

  getSIITaxF22Repository() {
    return this.siiTaxF22Repository;
  }

  getSIITaxF29Repository() {
    return this.siiTaxF29Repository;
  }

  getExpenseWageRepository() {
    return this.expenseWageRepository;
  }

  getSIITokenRepository() {
    return this.siiTokenRepository;
  }

  getClientDataRepository() {
    return this.clientDataRepository;
  }

  getProviderDataRepository() {
    return this.providerDataRepository;
  }

  getClientRepository() {
    return this.clientRepository;
  }

  getProviderRepository() {
    return this.providerRepository;
  }

  getPreUserRepository() {
    return this.preUserRepository
  }

  getPreCompanyRepository() {
    return this.preCompanyRepository
  }

  getCollaboratorRepository() {
    return this.collaboratorRepository
  }

  getCollaboratorDataRepository() {
    return this.collaboratorDataRepository
  }

  getEmployeeRepository() {
    return this.employeeRepository
  }
  getEmployeeSalaryRepository() {
    return this.employeeSalaryRepository
  }

  getEmployeeDataRepository() {
    return this.employeeDataRepository
  }

  getSpotExpenseRepository() {
    return this.spotExpenseRepository
  }
}
