import { ReconciliationMovement } from "../domain/ReconciliationMovement";
import { ReconciliationMovementRepository } from "../domain/ReconciliationMovementRepository";

export function createFixedReconciliationMovementRepository(): ReconciliationMovementRepository {
  return {
    getReconciliationMovementByMovementUid,
    getReconciliationMovements,
  };
}

async function getReconciliationMovementByMovementUid(
  movementUid: string
): Promise<ReconciliationMovement | undefined> {
  const reconciliationmovements = getReconciliationMovements();
  console.log("reconciliationmovements", reconciliationmovements);
  return Promise.resolve(
    (await reconciliationmovements).find(
      (reconciliationmovement) =>
        reconciliationmovement.movement_uid === movementUid
    )
  );
}

function getReconciliationMovements(): Promise<ReconciliationMovement[]> {
  return Promise.resolve([
    {
      movement_uid: "1",
      reconciliation_balance: 80000,
      reconciliation_balance_currency: "CLP",
    },
    {
      movement_uid: "3",
      reconciliation_balance: 9000,
      reconciliation_balance_currency: "CLP",
    },
    {
      movement_uid: "4",
      reconciliation_balance: 80000,
      reconciliation_balance_currency: "CLP",
    },
    {
      movement_uid: "6",
      reconciliation_balance: 98000,
      reconciliation_balance_currency: "CLP",
    },
    {
      movement_uid: "7",
      reconciliation_balance: 59000,
      reconciliation_balance_currency: "CLP",
    },
    {
      movement_uid: "9",
      reconciliation_balance: 74000,
      reconciliation_balance_currency: "CLP",
    },
  ]);
}
