import {
  vectiAppApiUrl,
  vectiAppAuth0Domain,
  vectiAppAuth0ClientId,
  vectiAppAuth0RedirectUrl,
} from "./Config";
import { Auth0Client } from "@auth0/auth0-spa-js";

export abstract class APIRepository {
  private readonly url: string;
  private static auth0 = new Auth0Client({
    domain: vectiAppAuth0Domain(), //TODO: Parametrizar
    clientId: vectiAppAuth0ClientId(),
    authorizationParams: {
      redirect_uri: vectiAppAuth0RedirectUrl(), //TODO: Parametrizar
      audience: "https://" + vectiAppAuth0Domain() + "/api/v2/", //TODO: Parametrizar
    },
  });

  constructor(url: string | null = null) {
    if (url === null) {
      this.url = vectiAppApiUrl();
    } else {
      this.url = url;
    }
  }

  //  This method will be used to make a GET request to the API.
  //  It will take in a URL and an optional query parameters object.
  //  It will return a Promise that will resolve to the data returned by the API.
  //  If the API returns an error, it will reject with the error.
  protected async doGet<TResponse>(
    path: string,
    queryparams: Record<string, string> | null = null,
    url: string = this.url
  ): Promise<TResponse> {
    //console.log("ApiRepository:doGet");

    const params = queryparams
      ? new URLSearchParams(queryparams)
      : new URLSearchParams();
    //console.log("ApiRepository:doGet", path, params.toString(), url);
    const callUrl = new URL(path + "?" + params.toString(), url).toString();
    //console.log("callUrl 1:" + callUrl);
    //console.log("vectiAppAuth0Domain():" + vectiAppAuth0Domain());

    return new Promise<TResponse>((resolve, reject) => {
      APIRepository.auth0
        .getTokenSilently({
          authorizationParams: {
            audience: "https://" + vectiAppAuth0Domain() + "/api/v2/", //TODO: Parametrizar
            scope: "email",
          },
        })
        .then((token: any) => {
          //  console.log("token: ", token);
          //console.log("callUrl X:", callUrl);
          fetch(callUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => {
              //              console.log("response: " + response.status);

              //   console.log("response api: " + JSON.stringify(response));
              // console.log("callUrl: " + JSON.stringify(callUrl), "token: " + JSON.stringify(token));


              if (response.ok) {
                //           console.log("item ok " + response.status);
                return response.json();
              } else if (response.status == 404) {
                //    console.log("item not found: " + response.status);
                return response.json();
                /*
                if (Array.isArray(response)) {
                  resolve([] as TResponse);
                } else {
                  resolve({} as TResponse);
                }
                */
              } else {
                console.log("error: " + response.statusText);
                //  throw new Error(response.statusText);
              }
            })
            // and return the result data.
            .then((data) => {
              //              console.log("retornando data: " + JSON.stringify(data));
              resolve(data as TResponse);
            })
            .catch((error) => {
              console.log("error: " + error);
              // Rechazar la promesa en caso de error
              reject(new Error(error));
            });
        })
        .catch((error: any) => {
          console.log("error Api Respository: " + error);
          reject(new Error(error));
        });
    });
  }

  protected async doPostNew<TRequest>(
    path: string,
    queryparams: Record<string, string> | null,
    body: TRequest,
    url: string = this.url
  ): Promise<void> {

    const params = queryparams
      ? new URLSearchParams(queryparams)
      : new URLSearchParams();
    const callUrl = new URL(path + params.toString(), url).toString();
    return new Promise<void>((resolve, reject) => {
      APIRepository.auth0.getTokenSilently({
        authorizationParams: {
          audience: "https://" + vectiAppAuth0Domain() + "/api/v2/", //TODO: Parametrizar
          scope: "email",
        },
      })
        .then(async (token: any) => {
          await fetch(callUrl, { method: "POST", headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${token}`, }, body: JSON.stringify(body), })
            .then((response) => {
              console.log("response X: " + JSON.stringify(response.body));
              console.log("response2: " + JSON.stringify(response));
              //  console.log("callUrl: " + callUrl);
              if (!response.ok) {
                console.log("error response API: " + response.statusText + " status:" + response.status + " body:" + JSON.stringify(response.body));
                reject(Error(response.statusText));
                return;
              } else {
                console.log("response ok API: " + response.statusText + " status:" + response.status + " body:" + JSON.stringify(response.body));
                console.log("response ok API JSONX: " + JSON.stringify(response));
              }
              resolve();
            }).then((data) => {
              console.log("retornando data: " + JSON.stringify(data));
              resolve(data as void);
            })
          /*
                      .finally(() => {
                        console.log("finally API ");
                        // Independientemente de si hay un error o no, resuelve la promesa sin un valor específico
                        resolve();
                      });
                      */
        })
        .catch((error: any) => {
          console.log("error Api Respository: " + error);
          reject(error);
        }).finally(() => {
          //console.log("finally API ");
          // Independientemente de si hay un error o no, resuelve la promesa sin un valor específico
          resolve();
        }
        );
    });
  }



  protected async doPost<TRequest, TResponse>(
    path: string,
    queryparams: Record<string, string> | null,
    body: TRequest,
    url: string = this.url
  ): Promise<TResponse> {
    const params = queryparams
      ? new URLSearchParams(queryparams)
      : new URLSearchParams();
    const callUrl = new URL(path + params.toString(), url).toString();
    return APIRepository.auth0
      .getTokenSilently({
        authorizationParams: {
          audience: "https://" + vectiAppAuth0Domain() + "/api/v2/", //TODO: Parametrizar
          scope: "email",
        },
      })
      .then(async (token: any) => {
        const response = await fetch(callUrl, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        });
        if (!response.ok) {
          console.log("error response API: " + response.statusText + " status:" + response.status + " body:" + JSON.stringify(response.body));
          throw new Error(response.statusText);
        }

        console.log("response ok API: " + response.statusText + " status:" + response.status + " body:" + JSON.stringify(response.body));
        console.log("response ok API JSONX: " + JSON.stringify(response));

        return response.json() as Promise<TResponse>;
      })
      .catch((error: any) => {
        console.log("error Api Respository: " + error);
        throw error;
      });
  }


  protected async doPostFile<FormData extends globalThis.FormData, TResponse>(
    path: string,
    queryparams: Record<string, string> | null,
    body: FormData,
    url: string = this.url
  ): Promise<TResponse> {
    const params = queryparams
      ? new URLSearchParams(queryparams)
      : new URLSearchParams();
    const callUrl = new URL(path + params.toString(), url).toString();
    for (const pair of body.entries()) {
      console.log("body:" + pair[0] + ', ' + pair[1]);
    }
    console.log("body.file:" + body.get("file"))
    console.log("body.filename X:" + (body.get("file") as File).name)




    return APIRepository.auth0
      .getTokenSilently({
        authorizationParams: {
          audience: "https://" + vectiAppAuth0Domain() + "/api/v2/", //TODO: Parametrizar
          scope: "email",
        },
      })
      .then(async (token: any) => {
        const response = await fetch(callUrl, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            //  'Content-Type': 'multipart/form-data'
          },
          body: body,
        });
        if (!response.ok) {
          console.log("error response API: " + response.statusText + " status:" + response.status + " body:" + JSON.stringify(response.body));
          throw new Error(response.statusText);
        }

        console.log("response ok API: " + response.statusText + " status:" + response.status + " body:" + JSON.stringify(response.body));
        console.log("response ok API JSONX: " + JSON.stringify(response));

        return response.json() as Promise<TResponse>;
      })
      .catch((error: any) => {
        console.log("error Api Respository: " + error);
        throw error;
      });
  }




}
