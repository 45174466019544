export class PageFull<T> {
  constructor(
    readonly totalElements: number,
    readonly returnElements: number,
    readonly content: T[]
  ) { }

  getContent(): T[] {
    return [...this.content];
  }

  getTotalElements(): number {
    return this.totalElements;
  }

  getReturnElements(): number {
    return this.returnElements;
  }
}
