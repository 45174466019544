import { SIITaxF22 } from "~/context/SIITaxF22s/domain/SIITaxF22";
import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { SIITaxF22Repository } from "../domain/SIITaxF22Repository";

export function createApiSIITaxF22Repository(): SIITaxF22Repository {
  return new ApiSIITaxF22Repository();
}

export class ApiSIITaxF22Repository
  extends APIRepository
  implements SIITaxF22Repository
{
  constructor() {
    super(vectiAppApiUrl());
  }

  addSIITaxF22(siitaxf22: SIITaxF22): Promise<void> {
    console.log("addSIITaxF22", JSON.stringify(siitaxf22));

    return this.doPostNew("/api/siitaxf22s", null, {
      ...siitaxf22,
      uid: siitaxf22.uid.value,
      company_uid: siitaxf22.company_uid.value,
    });
  }
}
