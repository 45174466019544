import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { EmployeeSalaryRepository } from "../domain/EmployeeSalaryRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { EmployeeSalary } from "../domain/EmployeeSalary";
import { VectiResult } from "~/context/Shared/domain/VectiResult";
import { EmployeeSalaryData } from "~/context/EmployeesData/domain/EmployeeSalaryData";


export function createApiEmployeeSalaryRepository(): EmployeeSalaryRepository {
  return new APIEmployeeSalaryRepository();
}

export class APIEmployeeSalaryRepository
  extends APIRepository
  implements EmployeeSalaryRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  async addEmployeeSalary(employeeSalary: EmployeeSalary, employee_legal_id: string, employee_legal_name: string): Promise<VectiResult> {
    console.log("addEmployeeSalary: " + JSON.stringify(employeeSalary) + "employeeLegal:" + employee_legal_id + " employeeLegal:" + employee_legal_name);
    try {
      const salaryArray = new Array<EmployeeSalary>();
      const myEmployee = {
        uid: employeeSalary.employee_uid,
        company_uid: employeeSalary.company_uid,
        employee_start_date: new Date(),
        employee_end_date: new Date(),
        employee_first_name: '',
        employee_last_name: '',
        employee_email: '',
        employee_phone: '',
        employee_address: '',
        employee_city: '',
        employee_gender: '',
        employee_legal_id: employee_legal_id,
        employee_legal_name: employee_legal_name,
      }

      salaryArray.push(employeeSalary);
      const myEmployeeSalaryData = {
        employee: myEmployee,
        salaries: salaryArray,
      }


      const resp = await this.doPost<EmployeeSalaryData, EmployeeSalaryData>("/api/employeesalaries/add", null, {
        ...myEmployeeSalaryData,
      });
      console.log("addEmployeeSalary resp:" + JSON.stringify(resp));
      return new VectiResult(true, resp);

    } catch (error) {
      console.log("error addEmployeeSalary X:" + error);
      return new VectiResult(false, "Error: " + error);

    }
  }


  async addEmployeeSalarySimpleFile(myFile: File, companyId: string, month: string, year: string): Promise<VectiResult> {
    try {

      const formData = new FormData();
      formData.append('file', myFile);
      formData.append('companyId', companyId);
      formData.append('month', month);
      formData.append('year', year);

      formData.forEach((value, key) => {
        console.log("myFormData:" + key + " - " + value);
      });
      console.log("myFormData:" + JSON.stringify(formData));

      console.log("addEmployeeSalarySimpleFile Repository X: " + companyId + "month: " + month + " ,year:" + year + ",myFile:" + myFile.name);
      /*
      const myEmployeeSalaryData = {
        file: formData,
        companyId: companyId,
        month: parseInt(month),
        year: parseInt(year),
      } as EmployeeSalarySimpleFileData;
*/


      const resp = await this.doPostFile<FormData, VectiResult>("/api/employeesalaries/addsimplefile", null,
        formData
      );
      console.log("addEmployeeSalarySimpleFile resp:" + JSON.stringify(resp));
      return new VectiResult(true, resp);
    } catch (error) {
      console.log("error addEmployeeSalarySimpleFile X:" + error);
      return new VectiResult(false, "Error: " + error);
    }
  }

}
