import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { AccountRepository } from "../domain/AccountRepository";
import { vectiAppApiUrl } from "../../Shared/infrastructure/Config";
import { Account } from "../domain/Account";
import { Accounts } from "../domain/Accounts";

export function createApiAccountRepository(): AccountRepository {
  return new AccountAPIRepository();
}

export class AccountAPIRepository extends APIRepository implements AccountRepository {
  constructor() {
    super(vectiAppApiUrl());
  }
  addAccount(_account: Account): Promise<void> {
    throw new Error("Method not implemented.:");
  }
  deleteAccount(_accountUid: string): Promise<void> {
    throw new Error("Method not implemented.");
  }
  updateAccount(_account: Account): Promise<void> {
    throw new Error("Method not implemented.");
  }

  async getAccountsByCompanyUid(companyUid: string): Promise<Account[]> {
    //console.log("companyUid:" + companyUid);
    //return await this.doGet<Account[]>("/api/accounts/", { companyId: companyUid.toString() });
    return await this.doGet<Account[]>("/api/accounts/" + companyUid.toString());
  }

  getAccounts(): Promise<Account[]> {
    const accounts = this.doGet<Accounts>("/api/accounts", null);
    return accounts.then((accounts) => accounts.accounts);
  }


}
