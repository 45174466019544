import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { CollaboratorRepository } from "../domain/CollaboratorRepository";
import { Collaborator } from "../domain/Collaborator";

export function createApiCollaboratorRepository(): CollaboratorRepository {
  return new APICollaboratorRepository();
}

export class APICollaboratorRepository
  extends APIRepository
  implements CollaboratorRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  getCollaboratorsByCompany(companyId: string): Promise<Collaborator[]> {
    console.log("getCollaboratorsByCompany companyId: " + companyId);
    return this.doGet<Collaborator[]>("/api/collaborators/company/" + companyId.toString());
  }

}
