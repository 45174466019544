
export class SearchData {
  readonly searchTerm: string;
  readonly companyId: string;


  constructor(companyId: string, searchTerm: string) {
    this.searchTerm = searchTerm;
    this.companyId = companyId;
  }
}