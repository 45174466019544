import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { EmployeeDataRepository } from "../domain/EmployeeDataRepository";
import { EmployeeDataResume } from "../domain/EmployeeDataResume";
import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";

export function createApiEmployeeDataRepository(): EmployeeDataRepository {
  return new APIEmployeeDataRepository();
}

export class APIEmployeeDataRepository
  extends APIRepository
  implements EmployeeDataRepository {
  constructor() {
    super(vectiAppApiUrl());
  }


  getEmployeesWithResumeByCompany(companyId: string, year: number): Promise<EmployeeDataResume[]> {
    console.log("getEmployeesWithResumeByCompany companyId: " + companyId);
    return this.doGet<EmployeeDataResume[]>("/api/employeesdata/resume/company/" + companyId.toString() + "/year/" + year);
  }


}
