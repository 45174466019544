import { ref, toValue, watchEffect } from "vue";
import { Dependencies } from "../context/Shared/infrastructure/Dependencies";
import { getUserExtendedData } from "../context/Users/application/getUserExtendedData";
import { UserExtended } from "../context/Users/domain/UserExtended";

import store from "../store/index";
import { Account } from "~/context/Accounts/domain/Account";

// global state, created in module scope
const globalUserExtended = ref(null as UserExtended | null);

export function useUserExtended(user: any) {
  //console.log("********* llamnando useUserExtended");
  watchEffect(() => {
    if (!globalUserExtended.value) {
      // console.log("vuelvo a obtenerlo pq no tengo al userExt");
      //console.log("dentro de globalUserExtended.value:" + JSON.stringify(user));
      if (toValue(user) && toValue(user).uid !== undefined) {
        //console.log("toValue(user).uid: " + toValue(user).uid);
        const apiuser = toValue(user).uid.toString();
        //console.log("apiuser: " + apiuser);
        //console.log("JSON.apiuser: " + JSON.stringify(apiuser));
        getUserExtendedData(
          Dependencies.getInstance().getUserRepository(),
          apiuser
        ).then((user_ext) => {
          globalUserExtended.value = user_ext;
          store.commit("setUserExtended", user_ext);
          store.commit(
            "setInUseCompany",
            globalUserExtended.value?.default_company
          );
          // store.dispatch('setInUseCompany', globalUserExtended.value?.default_company);

          //console.log("storeUserCompaniesAndAccounts");
          try {
            if (globalUserExtended.value.companies.length > 0) {
              // store.commit("setUserCompanies", globalUserExtended.value.companies);
            }
            globalUserExtended.value.companies.forEach(async (company) => {
              const accounts: Account[] = await useAccountsCompany(
                company.uid.toString()
              );
              const acc2 = JSON.parse(JSON.stringify(accounts));
              console.log(
                //   "----->almacenando account:" + JSON.stringify(accounts)
              );
              //  console.log("cantidad de cuentas:" + acc2.accounts.length);
              // console.log("cantidad de JSON.stringify(accounts):" + JSON.stringify(accounts));

              store.commit("addUserCompanyAccount", acc2.accounts);
            });

          } catch (error) {
            console.error("Error en getUserExtended:" + error);
          }
        });
        //  console.log("----->ret: " + JSON.stringify(ret));
      } else {
        // Aqui el usuario no esta seteado
        //   console.log(
        // "tengo el usuario: " + JSON.stringify(globalUserExtended.value)
        //  );
      }
    }
  });

  //  console.log("user EXT!!!!: " + JSON.stringify(globalUserExtended.value));
  // console.log("default cia: " + JSON.stringify(globalUserExtended.value?.default_company));
  //  store.commit('setInUseCompany', globalUserExtended.value?.default_company);

  //this.$store.commit("setDefaultCompany", this.selectedCompany);
  return globalUserExtended;
}

export function updateUserExtended(companyId: any) {
  if (!globalUserExtended.value) {
    console.log("No deberia producirse pq el usuario ya esta seteado");
  } else {
    console.log("actualizando usuario:" + JSON.stringify(globalUserExtended));
    if (globalUserExtended.value && toValue(companyId)) {
      globalUserExtended.value.default_company = companyId;
    } else {
      console.log("no pude actualizar user extended");
    }
  }

  return globalUserExtended;
}
