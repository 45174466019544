import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { ProviderDataRepository } from "../domain/ProviderDataRepository";
import { ProviderDataCreditor } from "../domain/ProviderDataCreditor";

export function createApiProviderDataRepository(): ProviderDataRepository {
  return new APIProviderDataRepository();
}

export class APIProviderDataRepository
  extends APIRepository
  implements ProviderDataRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  getTopProviderCreditorsByCompanyUid(companyUid: string, top: number): Promise<ProviderDataCreditor[]> {
    console.log("getTopProviderCreditorsByCompanyUid companyUid: " + companyUid);
    return this.doGet<ProviderDataCreditor[]>("/api/providersdata/creditors/company/" + companyUid.toString() + "/top/" + top.toString());
  }

  getProvidersWithCredByCompany(companyId: string): Promise<ProviderDataCreditor[]> {
    console.log("getProvidersWithCredByCompany companyId: " + companyId);
    return this.doGet<ProviderDataCreditor[]>("/api/providersdata/creditors/company/" + companyId.toString());
  }




}
