import { CompanyId } from "~/context/Company/domain/CompanyId";
import { VectiResult } from "~/context/Shared/domain/VectiResult";
import { Dependencies } from "~/context/Shared/infrastructure/Dependencies";
import { getSynchronization } from "~/context/Synchronizations/application/getSynchronization";
import { getSynchronizeAutoconciliationLastMonth } from "~/context/Synchronizations/application/getSynchronizeAutoconciliationLastMonth";
import { getSynchronizeBankLastMonth } from "~/context/Synchronizations/application/getSynchronizeBankLastMonth";
import { getSynchronizeSIILastMonth } from "~/context/Synchronizations/application/getSynchronizeSIILastMonth";
import { getSynchronizeBankYearMonth } from "~/context/Synchronizations/application/getSynchronizeBankMonths";
import { getSynchronizeSIIYearMonth } from "~/context/Synchronizations/application/getSynchronizeSIIMonths";
import { getSynchronizeAutoreconciliationYearMonth } from "~/context/Synchronizations/application/getSynchronizeAutoreconciliationMonths";

export function useSynchronize(companyId: string) {
  return getSynchronization(Dependencies.getInstance().getSynchronizationRepository(), new CompanyId(companyId));
}

export async function useSynchcronizeBank(companyId: string): Promise<VectiResult> {
  try {
    console.log("call useSynchcronizeBank:" + companyId);
    const ret = await getSynchronizeBankLastMonth(Dependencies.getInstance().getSynchronizationRepository(), new CompanyId(companyId));
    return new VectiResult(true, JSON.stringify(ret));

  } catch (error) {
    return new VectiResult(false, JSON.stringify(error));
  }
}

export async function useSynchcronizeSII(companyId: string): Promise<VectiResult> {
  try {
    console.log("call useSynchcronizeSII:" + companyId);
    const ret = await getSynchronizeSIILastMonth(Dependencies.getInstance().getSynchronizationRepository(), new CompanyId(companyId));
    return new VectiResult(true, JSON.stringify(ret));
  } catch (error) {
    return new VectiResult(false, JSON.stringify(error));
  }
}

export async function useSynchcronizeAutoconciliation(companyId: string): Promise<VectiResult> {
  try {
    console.log("call useSynchcronizeAutoconciliation:" + companyId);
    const ret = await getSynchronizeAutoconciliationLastMonth(Dependencies.getInstance().getSynchronizationRepository(), new CompanyId(companyId));
    return new VectiResult(true, JSON.stringify(ret));
  } catch (error) {
    return new VectiResult(false, JSON.stringify(error));
  }
}

export async function useSynchcronizeBankMonths(companyId: string, months: string[]): Promise<VectiResult> {
  try {
    if (months.length == 0) {
      return new VectiResult(false, "months is empty");
    }
    console.log("call useSynchcronizeBankMonths:" + companyId + "months:" + JSON.stringify(months));
    const ret = await getSynchronizeBankYearMonth(Dependencies.getInstance().getSynchronizationRepository(), new CompanyId(companyId), months);
    console.log("call useSynchcronizeBankMonths ret:" + JSON.stringify(ret));

    return new VectiResult(true, JSON.stringify(ret));
  } catch (error) {
    return new VectiResult(false, JSON.stringify(error));
  }
}

export async function useSynchcronizeSIIMonths(companyId: string, months: string[]): Promise<VectiResult> {
  try {
    if (months.length == 0) {
      return new VectiResult(false, "months is empty");
    }
    console.log("call useSynchcronizeSIIMonths:" + companyId + "months:" + JSON.stringify(months));
    const ret = await getSynchronizeSIIYearMonth(Dependencies.getInstance().getSynchronizationRepository(), new CompanyId(companyId), months);
    console.log("call useSynchcronizeSIIMonths ret:" + JSON.stringify(ret));

    return new VectiResult(true, JSON.stringify(ret));
  } catch (error) {
    return new VectiResult(false, JSON.stringify(error));
  }
}

export async function useSynchcronizeAutoreconciliationMonths(companyId: string, months: string[]): Promise<VectiResult> {
  try {
    if (months.length == 0) {
      return new VectiResult(false, "months is empty");
    }
    console.log("call useSynchcronizeAutoreconciliationMonths:" + companyId + "months:" + JSON.stringify(months));
    const ret = await getSynchronizeAutoreconciliationYearMonth(Dependencies.getInstance().getSynchronizationRepository(), new CompanyId(companyId), months);
    console.log("call useSynchcronizeAutoreconciliationMonths ret:" + JSON.stringify(ret));

    return new VectiResult(true, JSON.stringify(ret));
  } catch (error) {
    return new VectiResult(false, JSON.stringify(error));
  }
}


