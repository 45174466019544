import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { ProviderRepository } from "../domain/ProviderRepository";
import { Provider } from "../domain/Provider";
import { ProviderDataFull } from "~/context/ProvidersData/domain/ProviderDataFull";

export function createApiProviderRepository(): ProviderRepository {
  return new APIProviderRepository();
}

export class APIProviderRepository
  extends APIRepository
  implements ProviderRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  getProvidersByCompany(companyId: string): Promise<Provider[]> {
    console.log("getProvidersByCompanyId companyId: " + companyId);
    return this.doGet<Provider[]>("/api/providers/company/" + companyId.toString());
  }

  getProvider360ByCompanyAndId(companyId: string, providerId: string): Promise<ProviderDataFull> {
    console.log("getProvider360ByCompanyAndId companyId: " + companyId + " providerId: " + providerId);
    const ret = this.doGet<ProviderDataFull>("/api/providers/provider/" + providerId.toString() + "/company/" + companyId.toString());
    console.log("getProvider360ByCompanyAndId ret: " + JSON.stringify(ret));
    return ret;
  }

}
