import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { Client } from "../domain/Client";
import { ClientRepository } from "../domain/ClientRepository";
import { ClientDataFull } from "~/context/ClientsData/domain/ClientDataFull";

export function createApiClientRepository(): ClientRepository {
  return new APIClientRepository();
}

export class APIClientRepository
  extends APIRepository
  implements ClientRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  getClientsByCompany(companyId: string): Promise<Client[]> {
    console.log("getClientsByCompanyId companyId: " + companyId);
    return this.doGet<Client[]>("/api/clients/company/" + companyId.toString());
  }

  getClient360ByCompanyAndId(companyId: string, clientId: string): Promise<ClientDataFull> {
    console.log("getClientByCompanyAndId companyId: " + companyId);
    return this.doGet<ClientDataFull>("/api/clients/client/" + clientId.toString() + "/company/" + companyId.toString());
  }

}
