<template>
  <v-app>
    <!-- Fondo con color navy claro -->
    <v-card fluid class="landing-bg fill-height d-flex align-center justify-center">


      <v-container width="75%">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="6">

                <span class="landing-title">Un copiloto para la gestión de tu<br />
                  <span class="move-title">empresa</span>
                </span>
              </v-col>
              <v-col cols="6">

                <v-img :width="300" aspect-ratio="16/9" cover src="../img/landing.svg"></v-img>

              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            Conecta
            tu información, simplifica la gestión y deja que Vecti
            te ayude a tomar decisiones informadas.
          </v-col>


        </v-row>
        <v-row>
          <v-col>

            <v-btn color="primary" @click="login">
              ¡Comienza ahora!
            </v-btn>

          </v-col>
        </v-row>
      </v-container>



    </v-card>
  </v-app>
</template>

<script lang="ts">
import { useAuth0 } from "@auth0/auth0-vue";
import { useRouter } from 'vue-router';

export default {
  setup() {

    const { loginWithPopup, logout, user, isAuthenticated, isLoading } = useAuth0();

    const router = useRouter();

    const login = () => {
      loginWithPopup().then((_res) => {
        console.log("Logged in!");
        useUser();


        router.push('/');
      });
    };

    return {
      login,
      isAuthenticated,
      logout,
      user,
      isLoading,
    };

  },

  name: "LandingPage",

};



</script>

<style scoped>
/* Color navy claro para el fondo */
.bg-navy-light {
  background-color: #5381be;
}

/* Ajustes para el texto en azul claro */
.blue-text {
  color: #4fc3f7;
}

/* Personalización para el color del botón */
.primary--text {
  color: #ff9800;
}

.move-title {
  color: #00008B;
  visibility: hidden;
  position: relative;
}


.move-title::before {
  visibility: visible;
  position: absolute;
  content: "empresa";
  display: inline-block;
  animation-name: animate;
  animation-duration: 7s;
  animation-iteration-count: 1;
}


@keyframes animate {
  100% {
    content: "empresa";
  }

  20% {
    content: "emprendimiento";
  }

  40% {
    content: "negocio";

  }

  60% {
    content: "pyme";
  }

  80% {
    content: "startup";
  }
}
</style>