<template>

  <div>
    <div v-if="companies.length > 0">
      <div v-if="store.getters.getShowLarge">
        <label for="company">estas trabajando en: </label>
      </div>
      <div>
        <select id="company" v-model="selectedCompany" @change="onCompanyChange">
          <option v-for="company in companies" :key="company.uid" :value="company.uid">
            {{ company.company_name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";


export default {
  props: {
    listCompanies: {
      type: Object,
      required: true,
    },
    default_company: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      //      userExtended: useUserExtended(),

      selectedCompany: "",

      companies: [],

    };
  },
  async mounted() {
    this.companies = this.listCompanies;
    if (this.$store.state.inUseCompany) {
      // console.log("seleccionado del store");
      this.selectedCompany = this.$store.state.inUseCompany;
    } else {
      // console.log("seleccionado por defecto");
      this.selectedCompany = this.default_company;
    }

    //console.log("mounted");
    //console.log("companies:" + JSON.stringify(this.listCompanies));
    //console.log("defatult:" + JSON.stringify(this.default_company));
    //no funciona llamar a globalcompanies
    //this.companies =globalCompanies;
    //    this.companies = useUserCompanies();
    //    this.selectedCompany = useSelectedCompany().value.uid.value;
    //   this.default_company = useSelectedCompany();
  },
  computed: {
    inUseCompany() {
      //console.log("en inUseCompany:" + this.$store.state.inUseCompany);
      return this.$store.state.inUseCompany

    }
  },
  methods: {
    onCompanyChange() {

      //     console.log("userextended original:" + JSON.stringify(useUserExtended()));
      //updateUserExtended(this.selectedCompany);
      console.log("this.selectedCompany:" + JSON.stringify(this?.selectedCompany));

      this.$store.commit("setInUseCompany", this.selectedCompany);
      //this.$emit("change-company-selected", this.selectedCompany);
    },
  },

};
</script>
