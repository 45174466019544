import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { ExpenseWageRepository } from "../domain/ExpenseWageRepository";
import { ExpenseWage } from "../domain/ExpenseWage";

export function createApiExpenseWageRepository(): ExpenseWageRepository {
  return new ApiExpenseWageRepository();
}

export class ApiExpenseWageRepository
  extends APIRepository
  implements ExpenseWageRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  addExpenseWage(expenseWage: ExpenseWage): Promise<void> {
    console.log("call addExpenseWage", JSON.stringify(expenseWage));
    try {
      return this.doPostNew("/api/expensewages", null, {
        ...expenseWage,
        uid: expenseWage.uid.value,
        company_uid: expenseWage.company_uid,
      });
    } catch (error) {
      console.log("error addExpenseWage:" + error);
      return Promise.reject();
    }
  }
}
