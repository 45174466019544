import { User } from "../domain/User";
import { UserNotFoundError } from "../domain/UserNotFoundError";
import { UserRepository } from "../domain/UserRepository";

export function getUserData(userRepository: UserRepository): Promise<User> {
  return new Promise<User>((resolve, reject) => {
    userRepository
      .getUserData()
      .then((user) => {
        resolve(user);
      })
      .catch((_error) => {
        reject(new UserNotFoundError("User not found"));
      });
  });
}
