import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { ReconciliationRepository } from "../domain/ReconciliationRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { Reconciliation } from "../domain/Reconciliation";
import { ReconciliationWithReference } from "~/context/Shared/domain/ReconciliationWithReference";

import { ReconciliationReferenceIdAndType } from "~/context/Shared/domain/save/ReconciliationReferenceIdAndType";

export function createApiReconciliationRepository(): ReconciliationRepository {
  return new ApiReconciliationRepository();
}

export class ApiReconciliationRepository extends APIRepository implements ReconciliationRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  deleteReferences(reconciliationReferenceIdAndType: ReconciliationReferenceIdAndType): Promise<void> {
    return this.doPostNew<ReconciliationReferenceIdAndType>("/api/compose/reconciliation/references/general/remove", null, reconciliationReferenceIdAndType);
  }

  saveReconciliationAndReferences(reconciliationAndReferencesObject: ReconciliationReferenceIdAndType): Promise<void> {
    console.log("call api /compose/reconciliation/references/general:" + JSON.stringify(reconciliationAndReferencesObject));
    return this.doPostNew<ReconciliationReferenceIdAndType>("/api/compose/reconciliation/references/general", null, reconciliationAndReferencesObject);
  }


  getReconciliation(reconciliationUid: string): Promise<Reconciliation | undefined> {
    return this.doGet<Reconciliation>("/api/reconciliations/" + reconciliationUid.toString());
  }

  async getReferencesByReferenceObjectAndType(objectId: string, type: string): Promise<ReconciliationWithReference> {
    const response = await this.doGet<ReconciliationWithReference>("/api/compose/reconciliation/references/type/" + type.toString() + "/object/" + objectId.toString());
    console.log("getReferencesByReferenceObjectAndType objectId:" + objectId + " - type:" + type);
    console.log("getReferencesByReferenceObjectAndType response:" + JSON.stringify(response));
    return response;
  }

  addReconciliation(_reconciliation: Reconciliation): Promise<void> {
    throw new Error("Method not implemented.");
  }
  getReconciliationsByTypeAndUid(_reconciliationTypeId: string, _reconciliationPrincipalUid: string): Promise<Reconciliation[] | undefined> {
    throw new Error("Method not implemented.");
  }
  getReconciliationsByTypeAndCompanyId(_reconciliationTypeId: string, _reconciliationCompanyId: string): Promise<Reconciliation[] | undefined> {
    throw new Error("Method not implemented.");
  }
  getReconciliationsByCompanyId(_reconciliationCompanyId: string): Promise<Reconciliation[] | undefined> {
    throw new Error("Method not implemented.");
  }


}
