import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { CollaboratorDataRepository } from "../domain/CollaboratorDataRepository";
import { CollaboratorDataResume } from "../domain/CollaboratorDataResume";

export function createApiCollaboratorDataRepository(): CollaboratorDataRepository {
  return new APICollaboratorDataRepository();
}

export class APICollaboratorDataRepository
  extends APIRepository
  implements CollaboratorDataRepository {
  constructor() {
    super(vectiAppApiUrl());
  }
  getCollaboratorDataResume(companyId: string): Promise<CollaboratorDataResume[]> {
    return this.doGet<CollaboratorDataResume[]>("/api/collaboratorsdata/company/" + companyId.toString() + "/resume");

  }



}
